/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import { imgixLoader } from '@delicious-simplicity/next-image-imgix-loader';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { ButtonLink, Icon, Loader } from '@/components/design-system';
import Link from '@/components/shared/Link';
import { useAuth0 } from '@/context/auth';
import { color, visuallyHidden } from '@/utils/styles';
import { useLightModeToggle } from '@/context/LightModeToggleContext';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { SUBSCRIBE_PAGE_URL, GIFT_PAGE_URL } from '@/lib/constants';

const navLinkStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: '0.8rem',
  padding: '1.5rem .5rem',
  fontSize: '.875rem',
};
const navLinkStylesElection = {
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: '0.8rem',
  padding: '1.5rem .5rem',
  fontSize: '1rem',
  color: '#000',
};

export const DesktopMenu = React.memo(() => {
  const { isAuthenticated, subscriber, loginWithRedirect, loading } = useAuth0();
  const router = useRouter();
  const { isLightModeActive } = useLightModeToggle();

  const isLinkActive = (routeName) => {
    const basePath = router?.asPath?.split('/')[1];
    if (`/${basePath}`?.includes(routeName)) {
      return true;
    }
    return false;
  };

  const isPlanReader = subscriber?.effectivePlan === 'READER';
  const isPlanInsider = subscriber?.effectivePlan === 'INSIDER';
  const isPlanInsiderPlus = subscriber?.effectivePlan === 'INSIDER_PLUS';
  const isPlanAllAccess = subscriber?.effectivePlan === 'ALL_ACCESS';

  const kidsUrl = 'https://www.bentkey.com';

  const isHomePageElection = router.pathname === '/' && isLightModeActive === true;
  const electionStyle = isHomePageElection ? navLinkStylesElection : navLinkStyles;
  const handleSegmentClick = () => {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('CTA Click', {
      ...abTestData,
      ...utmCookieData,
      label: 'Sign Up',
      location: isHomePageElection ? 'Election Home Page' : 'Home Page',
      destination: SUBSCRIBE_PAGE_URL,
    });
  };
  return (
    <nav
      css={(theme) => ({
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginLeft: 48,

        [theme.mq.hd]: {
          marginLeft: 64,
        },
      })}
    >
      <div
        className={`${isHomePageElection ? 'version2-headerLinks-election' : 'version2-headerLinks'}`}
        css={{ display: 'flex' }}
      >
        <Link
          className={location.pathname === '/' && 'active'}
          css={isHomePageElection ? navLinkStylesElection : navLinkStyles}
          href='/'
        >
          Home
        </Link>
        <Link
          className={(isLinkActive('/listen') || isLinkActive('/podcasts')) && 'active'}
          css={isHomePageElection ? navLinkStylesElection : navLinkStyles}
          href='/listen'
        >
          Listen
        </Link>
        <Link
          className={(isLinkActive('/watch') || isLinkActive('/episode')) && 'active'}
          css={isHomePageElection ? navLinkStylesElection : navLinkStyles}
          href='/watch'
        >
          Watch
        </Link>
        <Link
          className={isLinkActive('/discuss') && 'active'}
          css={isHomePageElection ? navLinkStylesElection : navLinkStyles}
          href='/discuss'
        >
          Discuss
        </Link>
        <Link css={isHomePageElection ? navLinkStylesElection : navLinkStyles} href='https://store.dailywire.com'>
          Shop
        </Link>
        <Link css={isHomePageElection ? navLinkStylesElection : navLinkStyles} href={kidsUrl}>
          Kids
        </Link>
        <Link css={isHomePageElection ? navLinkStylesElection : navLinkStyles} href={GIFT_PAGE_URL}>
          Gift Membership
          <svg
            fill='none'
            height='20'
            style={{ marginLeft: '4px' }}
            viewBox='0 0 16 16'
            width='20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12.7392 3.10883H3.40584C2.66948 3.10883 2.07251 3.71645 2.07251 5.14454V11.9303C2.23388 12.1341 2.43648 12.3003 2.66655 12.4174C2.89663 12.5345 3.14877 12.5998 3.40584 12.6088H12.7392C12.9963 12.5998 13.2484 12.5345 13.4785 12.4174C13.7085 12.3003 13.9111 12.1342 14.0725 11.9303V5.14454C14.0725 3.71645 13.4756 3.10883 12.7392 3.10883ZM2.73918 4.46597C2.73938 4.28606 2.80968 4.11359 2.93466 3.98637C3.05964 3.85916 3.22909 3.7876 3.40584 3.7874H8.73918V5.82311H2.73918V4.46597ZM13.4058 11.2517C13.4056 11.4316 13.3353 11.6041 13.2104 11.7313C13.0854 11.8585 12.9159 11.93 12.7392 11.9303H3.40584C3.22909 11.93 3.05964 11.8585 2.93466 11.7313C2.80968 11.6041 2.73938 11.4316 2.73918 11.2517V7.85883H8.73918V10.5731L10.0725 9.21597L11.4058 10.5731V7.85883H13.4058V11.2517ZM13.4058 5.82311H11.4058V3.7874H12.7392C12.9159 3.7876 13.0854 3.85916 13.2104 3.98637C13.3353 4.11359 13.4056 4.28606 13.4058 4.46597V5.82311Z'
              fill='white'
            />
          </svg>
        </Link>
      </div>
      <div css={{ display: 'flex', alignItems: 'center' }} style={{ flexShrink: '0' }}>
        <Link
          css={{
            display: 'inline-flex',
            alignItems: 'center',
            marginRight: '.25rem',
            padding: '1.5rem .5rem',
            fontSize: '.875rem',
            color: isHomePageElection ? color.black : color.white,
          }}
          href='/search/news'
        >
          <Icon css={{ marginRight: '.5em' }} name='SEARCH' size={20} />
          <span css={visuallyHidden}>Search</span>
        </Link>
        {!isAuthenticated && (
          <Link
            className='login-link'
            css={isHomePageElection ? navLinkStylesElection : navLinkStyles}
            data-testid='desktop-login'
            href='/login'
            onClick={(event) => {
              event.preventDefault();
              loginWithRedirect({
                appState: { targetUrl: window.location.pathname },
              });
            }}
          >
            Log In
          </Link>
        )}
        {isAuthenticated && (
          <div
            css={(theme) => ({
              position: 'relative',
              lineHeight: 1,
              '> div': {
                top: '100%',
                display: 'none',
                position: 'absolute',
                backgroundColor: theme.colors.gray95,
                left: '50%',
                padding: '0.5rem 0',
                transform: 'translateX(calc(-50% - .5rem))',
                textAlign: 'center',
                fontSize: theme.fontSizes[1],
              },

              '&:hover > div': {
                display: 'block',
              },

              '> div a': {
                display: 'block',
                padding: '.5rem 2rem',
              },
            })}
            data-testid='desktop-logged-in'
          >
            <Link
              css={[
                electionStyle,
                {
                  '@media (max-width: 1100px)': {
                    marginRight: '0 !important',
                  },
                },
              ]}
              href='/account/subscription'
            >
              {loading ? (
                <div
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    backgroundColor: '#0e0e0e',
                  }}
                >
                  <Loader color='#686868' hideLettermark={true} />
                </div>
              ) : (
                <Image
                  alt={subscriber?.userName ?? ''}
                  data-testid='desktop-profile-image'
                  height={32}
                  loader={(props) => imgixLoader(props, { fit: 'crop', ar: '1:1', mask: 'ellipse' })}
                  src={
                    subscriber?.profileImage ??
                    'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png'
                  }
                  width={32}
                />
              )}
            </Link>
            <div>
              <Link href='/account/profile'>Badges</Link>
              <Link href='/account/subscription'>Settings</Link>
            </div>
          </div>
        )}
        <div className={`${isHomePageElection ? 'menu-button-link-election' : 'menu-button-link'}`}>
          {!(isPlanInsiderPlus || isPlanInsider || isPlanReader || isPlanAllAccess) && (
            <ButtonLink data-testid='desktop-join' href={SUBSCRIBE_PAGE_URL} onClick={handleSegmentClick} size='small'>
              Sign Up
            </ButtonLink>
          )}
          {(isPlanInsiderPlus || isPlanInsider || isPlanReader) && (
            <ButtonLink
              css={{
                '@media (max-width: 1100px)': {
                  display: 'none',
                },
              }}
              href='/account/subscription'
              size='small'
            >
              Upgrade
            </ButtonLink>
          )}
          {isPlanAllAccess && (
            <ButtonLink
              css={{
                '@media (max-width: 1080px)': {
                  display: 'none',
                },
              }}
              href={GIFT_PAGE_URL}
              size='small'
            >
              Send Gift
            </ButtonLink>
          )}
        </div>
      </div>
    </nav>
  );
});

DesktopMenu.displayName = 'DesktopMenu';
