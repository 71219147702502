/* eslint-disable import/no-unresolved */
import { MIDDLEWARE_URL } from '@/lib/constants';
import type { GlobalBannerData } from '@/types/globalBanner';

export const getGlobalBannerData = async () => {
  try {
    const resp = await fetch(`${MIDDLEWARE_URL}/middleware/v4/getGlobalBanner?platform=web`);
    if (!resp.ok) {
      if (resp.status >= 400 && resp.status < 500) {
        return null;
      }
      const error = await resp.json();
      throw new Error(error.error);
    }
    const data = await resp.json();
    if (!data) {
      throw new Error('error parsing global banner data');
    }
    return data as GlobalBannerData;
  } catch (err) {
    console.error(err);
    return null;
  }
};
