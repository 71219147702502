/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import auth0, { WebAuth } from 'auth0-js';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import useLocalStorage from '@/hooks/useLocalStorage';
import { getChatApiWithToken, RecurlySubscription } from '@/graphql/chat';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { subscriptionToIdentifyTraits } from '@/lib/analytics';
import { TransactionManager, SessionStorage } from '@/lib/auth0';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { getActiveSubscription, subscriberHasPaidPlan, tokenShouldRefreshed } from '@/utils/helpers';
import { Subscriber } from 'types/subscriber';
import { User } from 'types/user';
import { theFormerlyKnownAsTwitterAPI } from '@/utils/helpers/the-formerly-known-as-twitter-api';
import { XPayload } from '@/types/xPayload';
import { clearUserData } from '@/lib/auth0/clearUserData';

type Auth0Context = {
  accessToken: string;
  auth0Client?: Auth0Client;
  isAuthenticated: boolean;
  loading: boolean;
  subscriptionLoading: boolean;
  subscriber?: Subscriber;
  subscription: RecurlySubscription | null;
  user?: User;
  webAuth: WebAuth;
  handleRedirectCallback: () => void;
  updateSubscriber: (user: User, accessToken: string) => Promise<void>;
  getIdTokenClaims: (getIdTokenClaimsOptions: GetIdTokenClaimsOptions) => Promise<IdToken> | undefined;
  loginWithRedirect: (redirectLoginOptions: RedirectLoginOptions) => Promise<void> | undefined;
  getTokenSilently: (getTokenSilentlyOptions?: GetTokenSilentlyOptions) => Promise<string> | undefined;
  logout: (logoutOptions: LogoutOptions) => void;
  injectLoginTransaction: (string) => injectLoginTransactionResult;
};

type injectLoginTransactionResult = {
  state: string;
  nonce: string;
};

type TokenData = {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: string[];
  scope: string;
  sub: string;
  'https://users.bentkey.services/email': string;
  'https://users.bentkey.services/profile_img': string;
  'https://users.bentkey.services/username': UserTraits;
};

type UserTraits = {
  badge_image_url: string;
  person_id: string;
  recurly_account_code: string;
  shopify_customer_id: number;
  username: string;
};

export const Auth0Context = React.createContext<Auth0Context>({
  accessToken: '',
  auth0Client: undefined,
  isAuthenticated: false,
  loading: true,
  subscriptionLoading: true,
  subscriber: undefined,
  subscription: null,
  user: undefined,
  handleRedirectCallback: () => null,
  updateSubscriber: async () => undefined,
  getIdTokenClaims: () => undefined,
  loginWithRedirect: () => undefined,
  getTokenSilently: () => undefined,
  logout: () => undefined,
  webAuth: null,
  injectLoginTransaction: () => null,
});
export const useAuth0 = () => React.useContext(Auth0Context);

class TokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TokenError';
  }
}

type TAuth0Provider = {
  onRedirectCallback?: (args: any) => void;
} & Auth0ClientOptions;

export const Auth0Provider: React.FC<TAuth0Provider> = ({ children, onRedirectCallback, ...initOptions }) => {
  const [accessToken, setAccessToken] = React.useState<string>('');
  const [auth0Client, setAuth0] = React.useState<Auth0Client>();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [subscriptionLoading, setSubscriptionLoading] = React.useState<boolean>(true);
  const [subscriber, setSubscriber] = React.useState<Subscriber | undefined>(undefined);
  const [subscription, setSubscription] = React.useState<RecurlySubscription | null>(null);
  const [user, setUser] = React.useState<User>();
  const [xData, setXData] = useLocalStorage('x_data', { hasAccount: false, twclid: null });
  const router = useRouter();

  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();

  React.useEffect(() => {
    async function initAuth0() {
      const dwUser = JSON.parse(localStorage?.getItem('dw_user') ?? null);
      const dwSubscriber = JSON.parse(localStorage?.getItem('dw_subscriber') ?? null);
      const dwSubscription = JSON.parse(localStorage?.getItem('dw_subscription') ?? null);
      let initialIsAuthenticated = false;

      if (dwUser && dwSubscriber && !isAuthenticated) {
        setUser(dwUser);
        setSubscriber(dwSubscriber);
        setSubscription(dwSubscription);
        setIsAuthenticated(true);
        setLoading(false);
        initialIsAuthenticated = true;
      }
      let auth0FromHook: Auth0Client;
      try {
        auth0FromHook = await createAuth0Client({
          ...initOptions,
          cacheLocation: (window as any).Cypress ? 'localstorage' : 'memory',
        });
        setAuth0(auth0FromHook);

        const isLogin = window.location.search.includes('code=') && window.location.search.includes('state=');

        if (isLogin) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback ? onRedirectCallback(appState) : router.replace(window.location.pathname);
        }

        if (!initialIsAuthenticated) {
          const isAuthenticated = await auth0FromHook.isAuthenticated();
          setIsAuthenticated(isAuthenticated);
          initialIsAuthenticated = isAuthenticated;
        }

        if (initialIsAuthenticated || isAuthenticated) {
          let user = null;

          if (!dwUser) {
            user = await auth0FromHook.getUser();
          } else {
            user = dwUser;
          }

          if (user) {
            const isFirstLogin = user['https://www.dailywire.com/loginsCount'] === 1;

            localStorage.setItem('dw_user', JSON.stringify(user));
            setUser(user);
            let accessToken = localStorage.getItem('access_token') || cookie.get('accessToken');
            if (!accessToken || tokenShouldRefreshed(accessToken)) {
              localStorage.removeItem('access_token');
              try {
                accessToken = await auth0FromHook.getTokenSilently();
                if (!accessToken) {
                  throw new TokenError('No access token');
                }
              } catch (err) {
                throw new TokenError('No access token');
              }
              localStorage.setItem('access_token', accessToken);
              cookie.set('accessToken', accessToken, { expires: 30 });
            }

            setAccessToken(accessToken);

            await updateSubscriber(user, accessToken, true);

            const sentAccountCreated = localStorage.getItem(`sentAccountCreated-${user.name}`);

            // Firing Account Created Segement Event
            if (!isFirstLogin && !sentAccountCreated) {
              localStorage.setItem(`sentAccountCreated-${user.name}`, 'true');
            } else if (isLogin && isFirstLogin && !sentAccountCreated) {
              window.__DW_Next_Bridge?.Analytics.logEvent('Account Created', {
                ...abTestData,
                ...utmCookieData,
                email: user.email,
                username: user.name,
                path: window.location.pathname,
              });
              localStorage.setItem(`sentAccountCreated-${user.name}`, 'true');

              const { loginsCount = 0, email = null } =
                JSON.parse(localStorage?.getItem('ajs_user_traits') ?? '{}') || {};

              if (!xData.hasAccount && email && loginsCount > 0) {
                const xPayload: XPayload = {
                  email: email,
                  eventId: 'tw-oe2kp-oe2l5',
                  value: '0.00',
                  content: {
                    content_name: 'New account created',
                    content_type: 'Account',
                  },
                };

                if (xData.twclid) {
                  xPayload.twclid = xData.twclid;
                }
                theFormerlyKnownAsTwitterAPI(xPayload);

                setXData({
                  ...xData,
                  hasAccount: true,
                });
              }
            }
            // Firing Signed In Segement Event
            if (isLogin) {
              window.__DW_Next_Bridge?.Analytics.logEvent('Signed In', {
                ...abTestData,
                ...utmCookieData,
                username: user.name,
              });
            }
          }
        } else {
          // https://segment.com/docs/connections/spec/identify/#anonymous-id
          window.analytics?.identify({ effectivePlan: 'FREE' });
        }

        setLoading(false);
        setSubscriptionLoading(false);
      } catch (err) {
        setLoading(false);
        setSubscriptionLoading(false);
        if (err instanceof TokenError) {
          clearUserData();
          auth0FromHook.loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
        }
        console.error(err, 'something went wrong in initAuth0');
      }
    }

    initAuth0();
    // FIXME: Make sure the deps requested by this ESLint rule don't
    // break the logic and pass them properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSubscriber = async (user: User, accessToken: string, initialUpdate = false) => {
    try {
      const recurlyId = user['https://www.dailywire.com/recurly_account_code'];
      const chatApi = getChatApiWithToken(accessToken as string);

      // TODO: Improve on error reporting if something went wrong in the process of creating a recurly subscriber
      if (!recurlyId) {
        throw new Error('No recurlyId');
      }

      const [personData, subscriptionsData, badgeData] = await Promise.all([
        chatApi.currentPerson(),
        chatApi.getRecurlySubscriptionsByAccount({ account_code: recurlyId }),
        chatApi.verifyAndAwardBadge(),
      ]);
      const currentPerson = personData.currentPerson;
      // TODO: Improve on error reporting if something went wrong in the process of retrieving a person
      if (!currentPerson) {
        throw new Error('No currentPerson');
      }
      const subscriptions = subscriptionsData.getRecurlySubscriptions;
      const onArrivalBadge = badgeData.verifyAndAwardBadge;

      const activeSubscription = getActiveSubscription(subscriptions as RecurlySubscription[]);

      const subscriber = {
        ...(currentPerson as Subscriber),
        showAds: !subscriberHasPaidPlan(currentPerson as Subscriber),
        onArrivalBadge,
      };
      localStorage.setItem('dw_subscriber', JSON.stringify(subscriber));
      setSubscriber(subscriber);

      //set window.showAds for ad injection (video unit specific)
      (window as any).showAds = subscriber.showAds;

      const subscriptionIdentifyTraits = subscriptionToIdentifyTraits(activeSubscription);

      localStorage.setItem('dw_subscription', JSON.stringify(activeSubscription));
      setSubscription(activeSubscription);

      if (initialUpdate) {
        window.analytics?.identify(user.sub, {
          username: currentPerson.userName,
          firstName: currentPerson.firstName,
          lastName: currentPerson.lastName,
          email: currentPerson.email,
          effectivePlan: currentPerson.effectivePlan,
          loginsCount: user['https://www.dailywire.com/loginsCount'],
          createdAt: user['https://www.dailywire.com/created_at'],
          ...subscriptionIdentifyTraits,
        });

        // WisePops Custom Properties
        if ((window as any).wisepops) {
          (window as any).wisepops('properties', {
            user: {
              effectivePlan: currentPerson.effectivePlan,
            },
          });
        }
      }
    } catch (error) {
      console.error(error, 'update subscriber error ');
    }
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const webAuth = new auth0.WebAuth({
    domain: initOptions.domain,
    clientID: initOptions.client_id,
    audience: initOptions.audience,
    redirectUri: initOptions.redirect_uri,
  });

  const transactionManager = new TransactionManager(SessionStorage, initOptions.client_id);

  const getCrypto = () => {
    return window.crypto;
  };

  const createRandomString = () => {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
    let random = '';
    const randomValues = Array.from(getCrypto().getRandomValues(new Uint8Array(43)));
    randomValues.forEach((v) => (random += charset[v % charset.length]));
    return random;
  };

  const encode = (value: string) => btoa(value);

  const injectLoginTransaction = (appState: string): injectLoginTransactionResult => {
    const state = encode(createRandomString());
    const nonce = encode(createRandomString());
    const code_verifier = createRandomString();

    transactionManager.create({
      state,
      nonce,
      code_verifier,
      scope: 'openid profile email',
      audience: initOptions.audience,
      appState,
      redirect_uri: initOptions.redirect_uri,
    });

    return { state, nonce };
  };

  return (
    <Auth0Context.Provider
      value={{
        accessToken,
        auth0Client,
        isAuthenticated,
        user,
        loading,
        subscriptionLoading,
        handleRedirectCallback,
        subscriber,
        subscription,
        updateSubscriber: (user, accessToken) => updateSubscriber(user, accessToken, false),
        getIdTokenClaims: (getIdTokenClaimsOptions) => auth0Client?.getIdTokenClaims(getIdTokenClaimsOptions),
        loginWithRedirect: (redirectLoginOptions) => auth0Client?.loginWithRedirect(redirectLoginOptions),
        getTokenSilently: (getTokenSilentlyOptions) => auth0Client?.getTokenSilently(getTokenSilentlyOptions),
        logout: (logoutOptions) => {
          logout(logoutOptions, auth0Client, user, abTestData, utmCookieData);
        },
        webAuth: webAuth,
        injectLoginTransaction,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
function logout(
  logoutOptions: LogoutOptions,
  auth0Client: Auth0Client,
  user: User | null,
  abTestData: any,
  utmCookieData: any,
) {
  //  Segment Analytics
  if (user && abTestData && utmCookieData) {
    window.__DW_Next_Bridge?.Analytics.logEvent('Signed Out', {
      ...abTestData,
      ...utmCookieData,
      username: user?.name,
    });
  }
  // refresh the analytics cookie and remove auth cookie
  // when a Signed Out event occurs.
  clearUserData();
  return auth0Client?.logout(logoutOptions);
}
