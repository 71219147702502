/* eslint-disable import/no-unresolved */
import * as React from 'react';

import { imgixLoader } from '@delicious-simplicity/next-image-imgix-loader';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Button, ButtonLink, Icon } from '@/components/design-system';
import Link from '@/components/shared/Link';
import { useAuth0 } from '@/context/auth';
import { useLightModeToggle } from '@/context/LightModeToggleContext';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { SUBSCRIBE_PAGE_URL, GIFT_PAGE_URL } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { subscriberHasPaidPlan, subscriberCanAccess } from '@/utils/helpers';
import { visuallyHidden, color } from '@/utils/styles';

const mobileMenuLinkStyles = {
  a: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
    color: color.gray92,
    fontSize: 20,
    fontWeight: 700,
    'svg, img, span': {
      marginRight: 24,
    },
  },
};

export const MobileMenu = React.memo(() => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const { isAuthenticated, loginWithRedirect, subscriber, user } = useAuth0();
  const { isLightModeActive } = useLightModeToggle();

  const router = useRouter();

  const handleToggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const kidsUrl = 'https://www.bentkey.com';

  const isHomePageElection = router.pathname === '/' && isLightModeActive;

  const handleSegmentClick = () => {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('CTA Click', {
      ...abTestData,
      ...utmCookieData,
      label: 'Sign Up',
      location: 'Election Home Page Mobile',
      destination: SUBSCRIBE_PAGE_URL,
    });
  };

  return (
    <>
      <Button data-testid='mobile-menu-open' onClick={handleToggleMenu} type='button' variant='naked'>
        <h2 css={visuallyHidden}>Menu</h2>
        <svg height='15' viewBox='0 0 24 15' width='24' xmlns='http://www.w3.org/2000/svg'>
          <g fill={`${isHomePageElection ? '#000' : '#fff'}`} fillRule='evenodd'>
            <path d='m0 0h16v1h-16z' />
            <path d='m0 7h24v1h-24z' />
            <path d='m0 14h20v1h-20z' />
          </g>
        </svg>
      </Button>
      <div
        style={{
          background: '#fff',
          transition: 'transform 0.3s ease-in-out',
          position: 'fixed',
          left: '0',
          top: '0',
          bottom: '0',
          width: '100%',
          zIndex: 100,
          WebkitOverflowScrolling: 'touch',
          transform: menuIsOpen ? 'translate(0)' : 'translateX(calc(-100%))',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            zIndex: 10,
            position: 'relative',
            height: '100%',
            WebkitOverflowScrolling: 'touch',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              padding: '32px',
            }}
          >
            <div>
              <Image
                alt='Daily Wire Logo'
                data-label='global-logotype'
                height='25'
                src='/images/version2/LogoDark.svg'
                width='200'
              />
            </div>
            <Button data-testid='mobile-menu-close' onClick={handleToggleMenu} type='button' variant='naked'>
              <span css={visuallyHidden}>Close</span>
              <Icon css={{ color: '#999' }} name='CLOSE' size={20} />
            </Button>
          </div>
          <div css={{ padding: '8px 32px 32px' }}>
            <nav css={mobileMenuLinkStyles}>
              <Link href='/' onClick={() => setMenuIsOpen(false)}>
                Home
              </Link>
              <Link href='/listen' onClick={() => setMenuIsOpen(false)}>
                Listen
              </Link>
              <Link href='/watch' onClick={() => setMenuIsOpen(false)}>
                Watch
              </Link>
              <Link href='/discuss' onClick={() => setMenuIsOpen(false)}>
                Discuss
              </Link>
              <Link href='https://store.dailywire.com' onClick={() => setMenuIsOpen(false)}>
                Shop
              </Link>
              <Link href={kidsUrl} onClick={() => setMenuIsOpen(false)}>
                Kids
              </Link>
              <Link href={GIFT_PAGE_URL} onClick={() => setMenuIsOpen(false)}>
                Gift Membership
              </Link>
              <Link href='/search/news' onClick={() => setMenuIsOpen(false)}>
                Search
              </Link>
              {isAuthenticated && (
                <>
                  <Link data-testid='mobile-logged-in' href='/account/profile' onClick={() => setMenuIsOpen(false)}>
                    {' '}
                    {user?.picture && (
                      <span css={{ height: 24 }}>
                        <Image
                          alt={user.name}
                          data-testid='mobile-profile-image'
                          height={24}
                          loader={(props) => imgixLoader(props, { fit: 'crop', ar: '1:1', mask: 'ellipse' })}
                          src={user.picture}
                          title={user.name}
                          width={24}
                        />
                      </span>
                    )}
                    Account
                  </Link>
                  <Link href='/account/subscription' onClick={() => setMenuIsOpen(false)}>
                    <Icon name='SETTINGS' size={24} />
                    Settings
                  </Link>
                </>
              )}
            </nav>
            {/*  Account Liks */}
            {!isAuthenticated && (
              <Button
                css={{ backgroundColor: '#000', color: '#fff', width: '100%' }}
                data-testid='mobile-login'
                onClick={(event) => {
                  event.preventDefault();
                  loginWithRedirect({
                    appState: { targetUrl: window.location.pathname },
                  });
                }}
                size='small'
                type='button'
                variant='secondary'
              >
                Login
              </Button>
            )}
            {!subscriberHasPaidPlan(subscriber) && (
              <ButtonLink
                css={{ width: '100%', marginTop: 16, fontWeight: 700 }}
                data-testid='mobile-join'
                href={SUBSCRIBE_PAGE_URL}
                onClick={() => {
                  setMenuIsOpen(false);
                  handleSegmentClick();
                }}
                size='small'
              >
                Sign Up
              </ButtonLink>
            )}
            {subscriberHasPaidPlan(subscriber) && !subscriberCanAccess(subscriber, ['ALL_ACCESS']) && (
              <ButtonLink
                css={{ width: '100%', marginTop: 24 }}
                href='/account/subscription'
                onClick={() => setMenuIsOpen(false)}
                size='small'
              >
                Upgrade
              </ButtonLink>
            )}
            {subscriberHasPaidPlan(subscriber) && subscriberCanAccess(subscriber, ['ALL_ACCESS']) && (
              <ButtonLink
                css={{ width: '100%', marginTop: 24 }}
                href={GIFT_PAGE_URL}
                onClick={() => setMenuIsOpen(false)}
                size='small'
              >
                Send a Gift
              </ButtonLink>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

MobileMenu.displayName = 'MobileMenu';
